import 'babel-polyfill';
import * as THREE from 'three';
import { RectAreaLightUniformsLib } from 'three/examples/jsm/lights/RectAreaLightUniformsLib.js';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import { Terminal } from 'xterm';
import LocalEchoController from 'local-echo';

window.addEventListener('DOMContentLoaded', () => {
  const term = new Terminal({
    cursorBlink: true,
    fontSize: 18 * 2,
    cols: 39,
    rows: 16,
  });

  term.open(document.querySelector('#terminal'));

  const echo = new LocalEchoController();
  term.loadAddon(echo);

  echo.addAutocompleteHandler(function (index, tokens) {
    if (index == 0) return ['ls', 'help', 'history', 'shutdown', 'clear', 'whoami', 'pizza', 'sudo', 'rm'];
    return [];
  });

  function promptTerminal() {
    term.focus();

    echo
      .read('$ ')
      .then((input) => {
        if (input) {
          const command = input.toLowerCase().split(' ');

          switch (command[0]) {
            case 'shutdown':
              if (items.button && items.button.isOn) items.button.callback();
              return;

            case 'hello':
            case 'hi':
            case 'hey':
            case 'help':
              echo.println(`hey whats up :)`);
              break;

            case 'sudo':
              echo.println(`yea, sure. let me get right on that.`);
              break;

            case 'rm':
              echo.println(`this is a website...`);
              break;

            case 'no':
              echo.println(`k, bye`);
              break;

            case 'quit':
            case 'exit':
            case 'die':
              echo.println(`wow, sad`);
              break;

            case 'ls':
              echo.println(`the list goes on and on...`);
              break;

            case '?':
            case '??':
            case '???':
            case '????':
            case '?????':
            case '??????':
            case '???????':
            case '????????':
            case '?????????':
            case 'what':
            case 'wtf':
              echo.println(`?`.repeat(input.length));
              break;

            case 'ha':
            case 'hah':
            case 'haha':
            case 'hahah':
            case 'hahaha':
            case 'hahahah':
            case 'hahahaha':
              echo.println(`ha`.repeat(input.length));
              break;

            case 'lol':
            case 'lool':
            case 'loool':
            case 'looool':
            case 'loooool':
            case 'lolo':
            case 'lolol':
            case 'lolololol':
              echo.println('l' + `o`.repeat(input.length) + 'l');
              break;

            case 'heh':
            case 'heheh':
            case 'hehehe':
            case 'heheheh':
            case 'hehehehe':
            case 'heheheheh':
              echo.println(`he`.repeat(input.length));
              break;

            case 'lmao':
            case 'lmfao':
              echo.println(`rofl`);
              break;

            case 'rofl':
              echo.println(`lmao`);
              break;

            case 'wow':
            case 'woah':
            case 'whoa':
            case 'wowie':
            case 'omg':
              echo.println(`i know, right?`);
              break;

            case 'asdf':
              echo.println(`ghjkl;`);
              break;

            case 'whoami':
              echo.println(`im jacob bijani! who are you?`);
              break;

            case 'pizza':
              echo.println(`pizza! pizza! pizza!`);
              break;

            case 'tida':
              echo.println(`<3`);
              break;

            case 'andrew':
              echo.println(`hey buddy :)`);
              break;

            case 'allan':
              echo.println(`call me, jacob`);
              break;

            case 'clear':
              term.clear();
              break;

            case 'history':
              echo.history.entries.forEach((line) => echo.println(line));
              break;

            default:
              echo.println(`${command[0]}: command not found`);
              break;
          }
        }

        echo.abortRead();
        promptTerminal();
      })
      .catch((error) => console.warn(error));
  }

  function focusTerminal() {
    setTimeout(() => {
      if (!term) return;
      term.focus();
      term.textarea.focus();
    }, 100);
  }

  function applyTerminalTexture() {
    if (!items.display || !items.button) return;

    if (!canvas.textLayer || !canvas.cursorLayer) return;
    canvas.textLayer.ctx = canvas.textLayer.getContext('2d');
    canvas.cursorLayer.ctx = canvas.cursorLayer.getContext('2d');

    canvas.buffer.ctx.fillRect(0, 0, canvas.buffer.width, canvas.buffer.height);

    canvas.buffer.ctx.drawImage(canvas.textLayer, 30 * 2, 20 * 2, canvas.textLayer.width * 0.5, canvas.textLayer.height * 0.5);
    canvas.buffer.ctx.drawImage(canvas.cursorLayer, 30 * 2, 20 * 2, canvas.cursorLayer.width * 0.5, canvas.cursorLayer.height * 0.5);

    textures.buffer.needsUpdate = true;

    if (items.button.isOn) {
      items.display.material.map = textures.buffer;
    } else {
      items.display.material.map = textures.black;
    }
  }

  let renderer;
  let scene;
  let camera;

  const items = {};
  const lights = {};
  const materials = {};

  const textures = {
    black: new THREE.TextureLoader().load(require('../img/black.png')),
  };

  const canvas = {
    buffer: document.createElement('canvas'),
    textLayer: document.querySelector('.xterm-text-layer'),
    cursorLayer: document.querySelector('.xterm-cursor-layer'),
  };

  canvas.buffer.width = canvas.buffer.height = 512 * 2;
  canvas.buffer.ctx = canvas.buffer.getContext('2d');
  canvas.buffer.ctx.fillStyle = 'black';
  textures.buffer = new THREE.CanvasTexture(canvas.buffer);

  const raycaster = new THREE.Raycaster();
  const mouse = new THREE.Vector2();

  const container = document.querySelector('#crt');

  const loader = new GLTFLoader();
  const dracoLoader = new DRACOLoader();
  dracoLoader.setDecoderPath(
    'https://raw.githubusercontent.com/mrdoob/three.js/f11d6e872d6b4fd12b6b2c3b8457756556ab0353/examples/js/libs/draco/',
  );
  loader.setDRACOLoader(dracoLoader);

  window.addEventListener('resize', () => {
    if (!camera || !renderer) return;

    camera.aspect = container.clientWidth / container.clientHeight;
    camera.updateProjectionMatrix();

    renderer.setSize(container.clientWidth, container.clientHeight);
  });

  window.addEventListener(
    'mousemove',
    function (event) {
      if (!renderer) return;

      // if canvas is fullscreen
      // mouse.x = (event.clientX / renderer.domElement.clientWidth) * 2 - 1;
      // mouse.y = -(event.clientY / renderer.domElement.clientHeight) * 2 + 1;

      // if canvas is not fullscreen
      const canvasBounds = renderer.getContext().canvas.getBoundingClientRect();
      mouse.x = ((event.clientX - canvasBounds.left) / (canvasBounds.right - canvasBounds.left)) * 2 - 1;
      mouse.y = -((event.clientY - canvasBounds.top) / (canvasBounds.bottom - canvasBounds.top)) * 2 + 1;

      if (items.button) {
        if (mouseIntersectObjects([items.button]).length > 0) {
          document.body.classList.add('cursor-pointer');
        } else {
          document.body.classList.remove('cursor-pointer');
        }
      }
    },
    false,
  );

  function objectInteractionCallback(clientX, clientY, objects, event) {
    // if canvas is fullscreen
    // mouse.x = (clientX / renderer.domElement.clientWidth) * 2 - 1;
    // mouse.y = -(clientY / renderer.domElement.clientHeight) * 2 + 1;

    // if canvas is not fullscreen
    const canvasBounds = renderer.getContext().canvas.getBoundingClientRect();
    mouse.x = ((clientX - canvasBounds.left) / (canvasBounds.right - canvasBounds.left)) * 2 - 1;
    mouse.y = -((clientY - canvasBounds.top) / (canvasBounds.bottom - canvasBounds.top)) * 2 + 1;

    const intersects = mouseIntersectObjects(objects);

    if (intersects.length > 0) {
      if (event) event.preventDefault();
      if (intersects[0].object && intersects[0].object.callback) intersects[0].object.callback();
    }
  }

  document.addEventListener(
    'touchend',
    function (event) {
      focusTerminal();

      if (!camera || !renderer || !items.button) return;
      objectInteractionCallback(event.changedTouches[0].clientX, event.changedTouches[0].clientY, [items.button], event);

      if (mouseIntersectObjects(Object.values(items)).length > 0) {
        echo.abortRead();
        promptTerminal();
      }
    },
    false,
  );

  window.addEventListener(
    'mousedown',
    function (event) {
      focusTerminal();
      if (!camera || !renderer || !items.button) return;
      objectInteractionCallback(event.clientX, event.clientY, [items.button], event);
    },
    false,
  );

  init();
  animate();

  function init() {
    camera = new THREE.PerspectiveCamera(40, container.clientWidth / container.clientHeight, 0.01, 1000.0);
    camera.rotateY(THREE.MathUtils.degToRad(40));
    camera.position.set(5.5, 2, 7.5);

    scene = new THREE.Scene();

    // lights
    RectAreaLightUniformsLib.init();

    lights.screenRect = new THREE.RectAreaLight(0xffffff, 0.25, 4, 4);
    lights.screenRect.position.set(0, 2, 3);
    scene.add(lights.screenRect);

    lights.ledRect = new THREE.RectAreaLight(0x00ff00, 1, 0.4, 0.4);
    lights.ledRect.position.set(1.2, 0.75, 2.5);
    scene.add(lights.ledRect);

    lights.ambient = new THREE.AmbientLight(0x5e5e5e, 1.04);

    lights.left = new THREE.DirectionalLight();
    lights.left.castShadow = true;
    lights.left.position.set(-4.163, 2.632, 2.771);

    lights.right = new THREE.DirectionalLight();
    lights.right.castShadow = true;
    lights.right.position.set(5.0, 7, 7.5);
    scene.add(lights.ambient, lights.left, lights.right);

    // ground
    materials.shadow = new THREE.ShadowMaterial({ opacity: 0.25, color: new THREE.Color(0x666666) });
    const plane = new THREE.Mesh(new THREE.PlaneGeometry(50, 50, 50), materials.shadow);
    plane.receiveShadow = true;
    plane.rotateX(-Math.PI / 2);
    scene.add(plane);

    // model
    loader.load(
      require('../img/crt.glb'),
      (gltf) => {
        // 10x scale
        gltf.scene.scale.multiplyScalar(10);

        gltf.scene.traverse((child) => {
          if (child.isMesh) {
            // enable shadows
            if (['body', 'body_border', 'no_emptiness', 'display_border', 'stand'].includes(child.name)) {
              child.castShadow = true;
              items[child.name] = child;
            }

            // tweak materials
            if (child.name == 'body') {
              child.material.roughness = 0.5;
              child.material.metalness = 0.2;
            }

            if (child.name == 'display_glass') {
              child.material.roughness = 0.04;
              child.material.opacity = 0.02;
              child.material.transparent = true;
            }

            if (child.name == 'display_patch') {
              child.material.roughness = 0.35;
              child.material.side = THREE.DoubleSide;
              child.material.color = new THREE.Color(0xffffff);
              child.material.map = textures.black;

              items.display = child;
            }

            if (child.name == 'power_button') {
              items.button = child;

              const buttonTravel = 0.001;
              child.position.z -= buttonTravel;
              items.button.isOn = true;

              child.callback = function () {
                if (items.button.isOn) {
                  items.button.isOn = false;

                  items.button.position.z += buttonTravel;

                  lights.screenRect.visible = false;
                  lights.ledRect.visible = false;

                  items.led.visible = false;

                  lights.ambient.visible = false;
                  lights.left.intensity *= 0.5;
                  lights.right.intensity *= 0.5;

                  materials.shadow.color = new THREE.Color(0x000000);
                  materials.shadow.opacity *= 0.5;

                  document.body.classList.add('lights-off');

                  term.clear();
                  term.reset();
                  promptTerminal();
                } else {
                  items.button.isOn = true;

                  items.button.position.z -= buttonTravel;

                  lights.screenRect.visible = true;
                  lights.ledRect.visible = true;

                  items.led.visible = true;

                  lights.ambient.visible = true;
                  lights.left.intensity *= 2;
                  lights.right.intensity *= 2;

                  materials.shadow.color = new THREE.Color(0x666666);
                  materials.shadow.opacity *= 2;

                  document.body.classList.remove('lights-off');
                }
              };
            }

            if (child.name == 'led') {
              items.led = child;
            }

            // console.log(child);
          }
        });

        gltf.scene.rotateY(THREE.MathUtils.degToRad(3));
        scene.add(gltf.scene);

        term.clear();
        term.reset();
        promptTerminal();
        echo.setInput('hello world');
        echo.setCursor(11);
      },

      (xhr) => console.log((xhr.loaded / xhr.total) * 100 + '% loaded'),
      (error) => console.warn('An error happened', error),
    );

    renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(container.clientWidth, container.clientHeight);
    renderer.shadowMap.enabled = true;
    renderer.shadowMap.type = THREE.PCFSoftShadowMap;
    renderer.outputEncoding = THREE.GammaEncoding;
    container.appendChild(renderer.domElement);
  }

  function animate() {
    requestAnimationFrame(animate);
    applyTerminalTexture();
    renderer.render(scene, camera);
  }

  function mouseIntersectObjects(objects) {
    if (!camera || !renderer) return;
    raycaster.setFromCamera(mouse, camera);
    return raycaster.intersectObjects(objects);
  }
});
